<script lang="ts">
	import { preventDefault } from 'svelte/legacy';

	import 'bulma/css/bulma.css';
	import { getCurrentUser, getVenues } from '../../../common/api';
	import { token, user } from '$lib/store';
	import { page } from '$app/stores';
	import { onMount } from 'svelte';
	import { UserRole, type Venue } from '../../../common/types';
	import { goto } from '$app/navigation';
	import Logo from '$lib/Logo.svelte';
	interface Props {
		children?: import('svelte').Snippet;
	}

	let { children }: Props = $props();

	let venues: Venue[] = [];

	let openMenu: boolean = $state(false);

	let showNav = $derived($user && $user.role === UserRole.Admin);

	onMount(async () => {
		// if we're already on the login page, don't redirect
		if ($page.url.pathname === '/login') return;
		console.log('Version ' + __VERSION__);
		if (!$token) goto('/login');
		try {
			if ($token) {
				$user = await getCurrentUser($token);
				venues = await getVenues($token);
			}
		} catch (error) {
			$token = '';
			goto('/login');
		}
	});
</script>

{#if $token && showNav}
	<nav class="navbar has-shadow is-fixed-top" aria-label="main navigation">
		<div class="navbar-brand">
			<a class="navbar-item" href="https://portal.draw.tate.org.uk/">
				<Logo />
			</a>

			<a
				role="button"
				class="navbar-burger"
				class:is-active={openMenu}
				aria-label="menu"
				aria-expanded="false"
				data-target="navbarBasicExample"
				href="/"
				onclick={preventDefault(() => (openMenu = !openMenu))}
			>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>

		<div id="navbarBasicExample" class="navbar-menu" class:is-active={openMenu}>
			<div class="navbar-start">
				<a
					href="/drawings"
					class="navbar-item"
					class:is-active={$page.url.pathname.startsWith('/drawings')}>Drawings</a
				>
				<a
					href="/canvases"
					class="navbar-item"
					class:is-active={$page.url.pathname.startsWith('/canvases')}>Canvases</a
				>
				<a
					href="/canvas-selection"
					class="navbar-item"
					class:is-active={$page.url.pathname.startsWith('/canvas-selection')}>Canvas selection</a
				>
				<a
					href="/collaborative"
					class="navbar-item"
					class:is-active={$page.url.pathname.startsWith('/collaborative')}
					>Collaborative sessions</a
				>
				<a
					href="/remote"
					class="navbar-item"
					class:is-active={$page.url.pathname.startsWith('/remote')}>Remote</a
				>
			</div>

			<div class="navbar-end">
				<div class="navbar-item">
					<div class="buttons">
						<a class="button is-light" href="/logout"> Log out </a>
					</div>
				</div>
			</div>
		</div>
	</nav>
{/if}

<main class:showNav>
	{@render children?.()}
</main>

<!-- Import global app styles -->
<style global>
	@font-face {
		font-family: 'tate';
		src: url('/fonts/TateNewPro-Regular.woff') format('woff');
		font-weight: bold;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'tate';
		src: url('/fonts/TateNewPro-Thin.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	/* html,
	body {
		font-family: 'tate', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
		height: 100%;
		position: relative;
	} */

	main {
		position: relative;
		display: block;
		box-sizing: border-box;
		height: 100%;
		padding: 2rem;
	}
	@media (max-width: 768px) {
		main {
			padding: 1rem;
		}
	}

	main.showNav {
		padding-top: 4em;
	}

	nav .navbar-item.is-active {
		color: #0072ce;
	}
</style>
